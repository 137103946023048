import { BaseGroup } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/commonComponents/IBaseItem";
import { translate } from "../plugins/i18n";

const sidebarItems: BaseGroup = [
  {
    header: `${translate("dashboard")}`,
    type: "H",
    permissions: ["DAS", "VI"],
  },
  {
    type: "I",
    icon: "mdi-view-dashboard",
    title: `${translate("dashboard")}`,
    to: "/reports/dashboard",
    permissions: ["DAS", "VI"],
  },
  {
    header: `${translate("atendency")}`,
    type: "H",
    permissions: [
      { code: "CHT", action: "VI" }, 
      { code: "MNG", action: "VI" },
      { code: "HIS", action: "VI" },
    ],
  },

  {
    type: "I",
    icon: "mdi-chat-processing-outline",
    title: `${translate("chats")}`,
    to: "/chat",
    permissions: ["CHT", "VI"],
  },
  {
    type: "I",
    icon: "mdi-account-supervisor-outline",
    title: `${translate("supervisor")}`,
    to: "/management/manager",
    permissions: ["MNG", "VI"],
  },
  {
    type: "I",
    icon: "mdi-card-search-outline",
    title: `${translate("ClosedCalls")}`,
    to: "/management/servicehistoryList",
    permissions: ["HIS", "VI"],

  },
  {
    header: `${translate("flow")}`,
    type: "H",
    permissions: ["FLW", "VI"],
  },
  {
    type: "I",
    icon: "mdi-sitemap",
    title: `${translate("flow")}`,
    to: "/flow",
    permissions: ["FLW", "VI"],
  },
  {
    header: `${translate("reports")}`,
    type: "H",
    permissions: ["RPT", "VI"],
  },

  {
    type: "I",
    icon: "mdi-chart-timeline-variant",
    title: `${translate("report")}`,
    to: "/reports/report",
    permissions: ["RPT", "VI"],
  },
  {
    type: "I",
    icon: "mdi-chart-bar-stacked",
    title: `${translate("reportRequisition")}`,
    to: "/reports/report-requisition",
    permissions: ["RPT", "VI"],
  },

  {
    type: "I",
    icon: "mdi-tooltip-account",
    title: `${translate("contacts")}`,
    to: "/contactslist",
    permissions: ["CTC", "VI"],
  },
  // {
  //   type: "G",
  //   group: "/",
  //   model: false,
  //   icon: "mdi-tooltip-account",
  //   title: `${translate("contacts")}`,

  //   children: [
  //     {
  //       type: "I",
  //       icon: "mdi-contacts",
  //       title: `${translate("contacts")}`,
  //       to: "/contactslist",
  //       permissions: ["CTC", "VI"],
  //     },

  //{
  //  type: "I",
  //  icon: "mdi-clock-outline",
  //  title: `${translate("shootingschedule")}`,
  //  to: "/shootingschedule",
  //  permissions: ["TSC", "VI"],
  //},
  //{
  //  type: "I",
  //  icon: "mdi-plus",
  //  title: `${translate("addHsm")}`,
  //  to: "/asset-trigger",
  //  permissions: ["HSM", "VI"],
  //},
  //{
  //  type: "I",flow
  //  icon: "mdi-chart-box-outline",
  //  title: `${translate("reportHsm")}`,
  //  to: "/report-hsm",
  //  permissions: ["HSR", "VI"],
  //},
  //   ],
  // },
  {
    header: `${translate("register")}`,
    type: "H",
    permissions: [
      { code: "CAN", action: "VI" },
      { code: "QUE", action: "VI" },
      { code: "BRE", action: "VI" },
      { code: "CAL", action: "VI" },
      { code: "SEA", action: "VI" },
      { code: "USU", action: "VI" },
    ],
  },

  {
    type: "I",
    icon: "mdi-cellphone-text",
    title: `${translate("channel")}`,
    to: "/register/channel",
    permissions: ["CAN", "VI"],
  },
  {
    type: "I",
    icon: "mdi-human-queue",
    title: `${translate("attendancyQueue")}`,
    to: "/register/atendancyqueue",
    permissions: ["QUE", "VI"],
  },

  //{
  //  type: "I",
  //  icon: "mdi-star-half-full",
  //  title: `${translate("attendencyrating")}`,
  //  to: "/register/attendencyrating",
  //  permissions: ["ART", "VI"],
  //},
  {
    type: "I",
    icon: "mdi-calendar",
    title: `${translate("break")}`,
    to: "/register/break",
    permissions: ["BRE", "VI"],
  },
  // {
  //   type: "I",
  //   icon: "mdi-clock-outline",
  //   title: `${translate("timeGroup")}`,
  //   to: "/register/schedule-list",
  //   permissions: ["TIM", "VI"],
  // },
  {
    type: "I",
    icon: "mdi-calendar-account",
    title: `${translate("calendar")}`,
    to: "/register/calendar-list",
    permissions: ["CAL", "VI"],
  },

  //{
  //  type: "I",
  //  icon: "mdi-tag-outline",
  //  title: `${translate("tag")}`,
  //  to: "/register/tag",
  //  permissions: ["TAG", "VI"],
  //},
  //{
  //  type: "I",
  //  icon: "mdi-emoticon-excited-outline",
  //  title: `${translate("humor")}`,
  //  to: "/register/feeling-monitor",
  //  permissions: ["HUM", "VI"],
  //},
  {
    type: "I",
    icon: "mdi-spellcheck",
    title: `${translate("satisfactionSurvey")}`,
    to: "/register/satisfaction-survey-list",
    permissions: ["SEA", "VI"],
  },
  // {
  //   type: "I",
  //   icon: "mdi-chip",
  //   title: `${translate("integrations")}`,
  //   to: "/register/integrations",
  //   permissions: ["INT", "VI"],
  // },

  // {
  //   type: "I",
  //   icon: "mdi-cart-outline",
  //   title: `${translate("product")}`,
  //   to: "/register/product",
  //   permissions: ["PRO", "VI"],
  // },
  {
    type: "I",
    icon: "mdi-account-supervisor-outline",
    title: `${translate("users")}`, //cadastro de usuário
    to: "/management/users",
    permissions: ["USU", "VI"],
  },

  {
    header: `${translate("administration")}`,
    type: "H",
    permissions: [
      { code: "ADM", action: "VI" },
    ],
  },

  {
    type: "I",
    icon: "mdi-office-building",
    title: `${translate("title.company")}`,
    to: "/administration/company-list",
    permissions: ["ADM", "VI"],
  },
  {
    type: "I",
    icon: "mdi-notebook",
    title: `${translate("plans")}`,
    to: "/administration/plans",
    permissions: ["ADM", "VI"],
  },
  // {
  //   type: "I",
  //   icon: "mdi-wrench",
  //   title: `${translate("maintenance")}`,
  //   to: "/administration/maintenance",
  //   permissions: ["MNT", "VI"],
  // },
  /*{
    type: "I",
    icon: "mdi-currency-usd",
    title: `${translate("financial")}`,
    to: "/administration/financial",
    permissions: ["ADM", "VI"],
  },*/
  {
    header: `${translate("title.configuration")}`,
    type: "H",
    permissions: [
      { code: "CNF", action: "VI" },
    ],
  },
  {
    type: "I",
    icon: "mdi-cog",
    title: `${translate("title.configuration")}`,
    to: "/register/configurations",
    permissions: ["CNF", "VI"],
  },
  // {
  //   type: "I",
  //   icon: "mdi-sale",
  //   title: `${translate("sales")}`,
  //   to: "/sales",
  //   children: [
  //     {
  //       type: "I",
  //       icon: "mdi-account-search",
  //       title: `${translate("prospection")}`,
  //       to: "/sales/prospection",
  //       permissions: ["SAL", "VI"],
  //     },
  //     {
  //       type: "I",
  //       icon: "mdi-presentation",
  //       title: `${translate("presentation")}`,
  //       to: "/sales/presentation",
  //       permissions: ["SAL", "VI"],
  //     },
  //     {
  //       type: "I",
  //       icon: "mdi-handshake",
  //       title: `${translate("negotiation")}`,
  //       to: "/sales/negotiation",
  //       permissions: ["SAL", "VI"],
  //     },
  //     {
  //       type: "I",
  //       icon: "mdi-point-of-sale",
  //       title: `${translate("saleActions")}`,
  //       to: "/sales/sale-actions",
  //       permissions: ["SAL", "VI"],
  //     },
  //   ],
  // },
  // {
  //   type: "I",
  //   icon: "mdi-store",
  //   title: `${translate("marketing")}`,
  //   to: "/marketing",
  //   children: [
  //     {
  //       type: "I",
  //       icon: "mdi-account-search",
  //       title: `${translate("prospection")}`,
  //       to: "/marketing/prospection-marketing",
  //       permissions: ["MKT", "VI"],
  //     },
  //     {
  //       type: "I",
  //       icon: "mdi-phone-message",
  //       title: `${translate("hsm")}`,
  //       to: "/marketing/hsm",
  //       permissions: ["MKT", "VI"],
  //     },
  //     {
  //       type: "I",
  //       icon: "mdi-account-group-outline",
  //       title: `${translate("group")}`,
  //       to: "/marketing/group",
  //       permissions: ["MKT", "VI"],
  //     },
  //     {
  //       type: "I",
  //       icon: "mdi-account-multiple-plus-outline",
  //       title: `${translate("leadNuturing")}`,
  //       to: "/marketing/lead-nuturing",
  //       permissions: ["MKT", "VI"],
  //     },
  //     {
  //       type: "I",
  //       icon: "mdi-gesture-double-tap",
  //       title: `${translate("cta")}`,
  //       to: "/marketing/cta",
  //       permissions: ["MKT", "VI"],
  //     },

  //     // {
  //     //   type: "I",
  //     //   icon: "mdi-presentation",
  //     //   title: `${translate("presentation")}`,
  //     //   to: "/sales/presentation",
  //     //   permissions: ["SAL", "VI"],
  //     // },
  //     // {
  //     //   type: "I",
  //     //   icon: "mdi-handshake",
  //     //   title: `${translate("negotiation")}`,
  //     //   to: "/sales/negotiation",
  //     //   permissions: ["SAL", "VI"],
  //     // },
  //     // {
  //     //   type: "I",
  //     //   icon: "mdi-point-of-sale",
  //     //   title: `${translate("saleActions")}`,
  //     //   to: "/sales/sale-actions",
  //     //   permissions: ["SAL", "VI"],
  //     // },
  //   ],
  // },
  // {
  //   type: "I",
  //   icon: "mdi-handshake-outline",
  //   title: `${translate("relationship")}`,
  //   to: "/relationship",
  //   permissions: ["DAS", "VI"],
  // },
  {
    header: `${translate("integrations")}`,
    type: "H",
    permissions: [
      { code: "INT", action: "VI" },
    ],
  },
  {
    type: "I",
    icon: "mdi-lan-connect",
    title: `${translate("integrations")}`,
    to: "/integrations",
    permissions: ["INT", "VI"],
  },
];
export default sidebarItems;
